export default {
    translations: {
      main: {
        maintenenceNotice:"O CONTEÚDO DO SITE ESTÁ SENDO ATUALIZADO NO MOMENTO!",
        aboutMe:"Sobre Mim"
      },
      home: {
        introductions:"Olá! Meu nome é João Victor Bueno de Caldas. Sou estudante de ciência da computação na ",
        aboutMe:"Estive interessado em programação desde 2015 quando passei a utilizar Ubuntu Linux e desde então tenho tido contato com a área.",
        currently:"Ultimamente tenho focado no desenvolvimento de aplicações frontend. Algumas das ferramentas que já utilizei são:",
        contact:"Me Contate: ",
        clickHere:"Clique Aqui Para Ver Projetos em que já Participei"
      }
    }
}