export default {
    translations: {
      main: {
        maintenenceNotice:"SITE CONTENT IS CURRENTLY BEING UPDATED!",
        aboutMe:"About Me"
      },
      home: {
        introductions:"Hello! My name is João Victor Bueno de Caldas. I'm a computer science student at ",
        aboutMe:"I've been infatuated with programming since 2015 when our family's computer started running Ubuntu linux and have been coding since.",
        currently:"Lately I've been focusing on developing frontend applications. Some of the tools I've used include:",
        contact:"Contact Me: ",
        clickHere:'Click Here to See Projects I\'ve Worked on'
      }
    }
}