import { Link } from "react-router-dom";
// Traducoes
import '../i18n/index'
import { useTranslation } from 'react-i18next';

export function NavBar() {
    const [t, i18n] = useTranslation();

    return (
        <div className="z-50 w-full bg-slate-800 text-white flex flex-row p-3 justify-between items-center">
            <Link to="/" className="ml-8 text-green-400 active:text-green-200 hover:text-green-600 text-center text-base sm:text-2xl font-black select-none">Jobucaldasの家</Link>
            <div className="items-center">
                <Link to="/about" className="text-green-400 active:text-green-200 hover:text-green-600 mr-8 select-none">{t("main.aboutMe")}</Link>
            </div>
        </div>
    );
}