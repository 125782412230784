import { NavBar } from "../components/NavBar";
import { Link } from "react-router-dom";
import { TypeAnimation } from 'react-type-animation';
import styles from '../css/CodeLikeTyping.css';

// Icones
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
// Traducoes
import '../i18n/index'
import { useTranslation } from 'react-i18next';

function Home() {
    const [t, i18n] = useTranslation();

    return (
        <div className="h-full min-h-screen flex flex-col align-center">
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/devicons/devicon@v2.15.1/devicon.min.css"></link>
            <NavBar />
            <div className="grow z-0 flex flex-col md:flex-row content-center self-center justify-center w-full">
                <img className='m-0 md:m-2 border border-0 md:border-4 border-black rouded-none md:rounded-full w-screen md:w-fit md:max-h-96 content-center self-center justify-center' src={ process.env.PUBLIC_URL + '/images/profile_pic.png' } alt="Profile"></img>
                <div className="flex-1 flex flex-col items-center m-2 content-center self-center justify-center h-75 max-w-2xl">
                    <div className="p-2 text-white text-lg text-justify">
                        <p className="m-2">{t("home.introductions")}<a className='underline hover:opacity-50' href="https://www.ufscar.br/">Universidade Federal de São Carlos</a>.</p>
                        <p className="m-2">{t("home.aboutMe")}</p>
                        <p className="m-2">{t("home.currently")}
                        <ul>
                            <li className="text-center p-6"><i class="devicon-react-original"> React</i>, <i class="devicon-react-original"> React Native</i>, <i class="devicon-nodejs-plain"> Node.js</i>, <i class="devicon-express-original"> Express</i>, <i class="devicon-flutter-plain"> Flutter</i>, <i class="devicon-nextjs-original"> Next.js</i>, <i class="devicon-redux-original"> Redux.js</i>, <i class="devicon-mysql-plain"> MySQL</i>, <i class="devicon-postgresql-plain"> PostgreSQL</i>, <i class="devicon-python-plain"> Python</i>, <i class="devicon-javascript-plain"> Javascript</i>, <i class="devicon-java-plain"> Java</i>, <i class="devicon-spring-plain"> SpringBoot</i>, <i class="devicon-docker-plain"> Docker</i>, <i class="devicon-c-plain"> C</i>, <i class="devicon-cplusplus-plain"> C++</i>, <i class="devicon-csharp-plain"> C#</i>, <i class="devicon-lua-plain"> Lua</i>, <i class="devicon-qt-original"> Qt</i>, <i class="devicon-unity-original"> Unity</i></li>
                        </ul>
                        </p>
                        <div className="flex justify-center gap-3">
                            <p>{t("home.contact")}</p>
                            <a className="hover:opacity-50" href="mailto:joaovictorbuenodecaldas@gmail.com"><MailOutlineIcon></MailOutlineIcon></a>
                            <a className="hover:opacity-50" href="https://www.linkedin.com/in/joaobuenoc/"><LinkedInIcon></LinkedInIcon></a>
                            <a className="hover:opacity-50" href="https://www.instagram.com/jobucaldas/"><InstagramIcon></InstagramIcon></a>
                            <a className="hover:opacity-50" href="https://github.com/jobucaldas"><GitHubIcon></GitHubIcon></a>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <Link to='/projects' className='text-green-400 active:text-green-200 hover:text-green-600 w-full p-6 text-center text-xl'>
                <TypeAnimation sequence={[
                    t("home.clickHere"),
                ]}
                wrapper="span"
                speed={50}
                className={styles.type}
                />
            </Link>
        </div >
    );
}

export default Home;