import { NavBar } from "../components/NavBar";
import GitHubIcon from '@mui/icons-material/GitHub';

// Traducoes
import '../i18n/index'
import { useTranslation } from 'react-i18next';

function Projects() {
    const [t, i18n] = useTranslation();

    return (
        <div className="h-full min-h-screen flex flex-col align-center">
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/devicons/devicon@v2.15.1/devicon.min.css"></link>
            <NavBar />
            <div className="grow z-0 m-2 flex flex-col md:flex-row content-center self-center justify-center w-full">
                <div className="content-center self-center p-6">
                    <img className='m-2 max-h-96 content-center self-center justify-center' src={ process.env.PUBLIC_URL + '/images/planner.png' } alt="Project"></img>
                    <div className="flex flex-row content-center items-center justify-center">
                        <a className="hover:opacity-50" href='https://play.google.com/store/apps/details?id=com.pet.ufscarplanner&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img width={180} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                        <a className="hover:opacity-50" href="https://github.com/petbccufscar/ufscar-planner"><GitHubIcon></GitHubIcon></a>
                    </div>
                </div>
                <div className="flex flex-col content-center self-center justify-center text-justify max-w-sm m-4 text-lg">
                    <h2 className="mx-2 text-xl bold">UFSCar Planner</h2>
                    <p className="mx-2">O aplicativo permite a organização e planejamento de atividades e matérias da UFSCar. Também é possível acessar o cardápio do Restaurante Universitário.
                    <br/> Algumas outras funcionalidades incluem simular média e frequência.</p>
                    <hr className="my-4"/>
                    <p className="mx-2">Tecnologias utilizadas:
                        <ul>
                            <li className="text-center text-lg"><i class="devicon-react-original"> React Native</i>, <i class="devicon-redux-original"> Redux.js</i>, <i class="devicon-javascript-plain"> Javascript</i></li>
                        </ul>
                    </p>
                </div>
            </div>
            <p className='text-yellow-400 bold w-full p-4 text-center text-xl'>{t("main.maintenenceNotice")}</p>
        </div >
    );
}

export default Projects;