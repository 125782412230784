import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import Home from './pages/Home';
import Login from './pages/Login';
import About from './pages/About';
import Projects from './pages/Projects';


function App() {
    sessionStorage.setItem("isLogged", 'false');
    sessionStorage.setItem("user", '');

    return (
        <BrowserRouter>
            <div className='absolute z-0 bg-gray-900 text-white min-h-full w-full'>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="login" element={<Login />} />
                    <Route path="about" element={<About />} />
                    <Route path="projects" element={<Projects />} />
                </Route>
            </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
