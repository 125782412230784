import { NavBar } from "../components/NavBar";
// Traducoes
import '../i18n/index'
import { useTranslation } from 'react-i18next';

function About() {
    const [t, i18n] = useTranslation();

    return (
        <div className="h-full min-h-screen flex flex-col">
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/devicons/devicon@v2.15.1/devicon.min.css"></link>
            <NavBar />
            <div className="grow z-0 m-2 flex flex-row content-center self-center justify-right w-full">
                <div className="text-xl p-4">
                    <p className="text-left">Full-Stack Developer</p>
                    <ul><li className="text-left p-4 max-w-md"><i class="devicon-react-original"> React</i>, <i class="devicon-react-original"> React Native</i>, <i class="devicon-nodejs-plain"> Node.js</i>, <i class="devicon-express-original"> Express</i>, <i class="devicon-flutter-plain"> Flutter</i>, <i class="devicon-nextjs-original"> Next.js</i>, <i class="devicon-redux-original"> Redux.js</i>, <i class="devicon-mysql-plain"> MySQL</i>, <i class="devicon-postgresql-plain"> PostgreSQL</i>, <i class="devicon-python-plain"> Python</i>, <i class="devicon-javascript-plain"> Javascript</i>, <i class="devicon-java-plain"> Java</i>, <i class="devicon-spring-plain"> SpringBoot</i>, <i class="devicon-docker-plain"> Docker</i>, <i class="devicon-c-plain"> C</i>, <i class="devicon-cplusplus-plain"> C++</i>, <i class="devicon-csharp-plain"> C#</i>, <i class="devicon-lua-plain"> Lua</i>, <i class="devicon-qt-original"> Qt</i>, <i class="devicon-unity-original"> Unity</i></li></ul>
                </div>
            </div>
            <p className='text-yellow-400 bold w-full p-4 text-center text-xl'>{t("main.maintenenceNotice")}</p>
        </div>
    );
}

export default About;